import {Link, NavLink} from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';


const Header = () => {
    return(
        <>
            <nav className="navbar bg-dark border-bottom border-body fixed-top navbar-expand-lg" data-bs-theme="dark">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/"><img src="/img/brand.png" alt="Logo" width="40" height="40"
                                                                  className="d-inline-block align-text-top mx-4"/>DocByte Design</NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <NavLink className="nav-link" to="/richiedi_preventivo">Richiedi Preventivo</NavLink>
                            <NavLink className="nav-link mx-lg-5" to="https://github.com/Doc082/">I miei Lavori</NavLink>
                            <NavLink className="nav-link" to="/chi_sono">Chi sono</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;